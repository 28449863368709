<template>
<v-sheet flat>

	<h2>Welcome</h2>
	<h3>Welcome to the UK Ambulance Trust's Digital Maturity Self-Assessment website.</h3>
	<v-alert color="primary" text class="mt-4">
		<render-content id="delegatealert"></render-content>
	</v-alert>
	<render-content id="delegatelanding"></render-content>
</v-sheet>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
export default {
	name: "DelgateWelcome",
	components: {
		RenderContent,
	},
};
</script>
